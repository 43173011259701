.Flex {
    display: flex;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyEvenly {
    justify-content: space-evenly;
}

.alignStart {
    align-items: flex-start;
}

.alignCenter {
    align-items: center;
}

.alignEnd {
    align-items: flex-end;
}

.directionRow {
    flex-direction: row;
}

.directionColumn {
    flex-direction: column;
}

.gap4 {
    gap: 4px;
}

.gap8 {
    gap: 8px;
}

.gap10 {
    gap: 10px;
}

.gap16 {
    gap: 16px;
}

.gap24 {
    gap: 16px;
}

.gap32 {
    gap: 32px;
}

.max {
    width: 100%;
}

.wrap {
    flex-wrap: wrap;
}

.nowrap {
    flex-wrap: nowrap;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;