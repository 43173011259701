
.loginImage {
  position: absolute;
  top: 20%;
  height: 1080px;
  width: 1920px;
  margin-bottom: 20px;
  object-fit: contain;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;