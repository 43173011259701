.home {
  max-width: 90rem;
  margin: auto;
  padding: 0 5%;
  box-sizing: content-box;

  a {
    position: relative;
  }


  @media screen and(max-width: 1440px) {
    .home {
      max-width: 60rem;
    }

    .br {
      display: none;
    }
  }
}


.p-20 {
  cursor: inherit;
}


@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;