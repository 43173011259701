.container {
  max-width: 1440px;
  margin-bottom: 60px;
}
.loader {
  position: absolute;
  margin-left: 30%;
  margin-top: 100px;
}
.btn {
  background-color: #7771dd;
  max-width: 180px;
  height: 60px;
}
.popUp{
  min-width: 400px;
}
.inp {
  border: 1px solid gray;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 50px;
}
.VStack{
  padding: 15px 0;
}
.table {
  max-width: 1440px;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  
  // Set consistent background colors
  tr:nth-of-type(odd) {
    background: #FFFFFF;
  }
  tr:nth-of-type(even) {
    background: #FFFFFF;
  }
  
  // Set consistent border colors
  td,
  th {
    border: 1px solid #E6E9F3;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    max-width: 40px;
    overflow: hidden;
  }
  
  // First column styling
  & th:first-child,
  & td:first-child {
    min-width: 40px;
    min-width: 40px;
  }
  & th:nth-child(2),
  & td:nth-child(2) {
    min-width: 280px;
    max-width: 280px;
    text-align: start;
    padding-left: 10px;
  }
  // Header styling
  th {
    background: #F8F9FA;
    color: #7F8190;
    height: 60px;

  }
  // Row styling
  tr {
    height: 30px;
  }

  th:not(:nth-child(2),:nth-child(1)) {
    height: 85px;
    min-width: 30px;
    position: relative;
    vertical-align: bottom;
    background: #F8F9FA;
    color: #7F8190;

    & > div {
      transform: rotate(-90deg);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: absolute;
      bottom: 5px;
      right: -75%;
      padding: 25px 0px;
    }
  }

  
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;