.table {
  max-width: 1380px;
  border-collapse: collapse;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
  
  tr:nth-of-type(odd) {
    background: #FFFFFF;
  }

  th {
    background: #FFFFFF;
    color: #7F8190;
    height: 90px;
  }

  td,
  th {
    padding: 15px, 20px;
    border: 1px solid #E6E9F3;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    // overflow: scroll;
    max-width: 150px;
    min-width: 150px;
    overflow: hidden;
  }
tr {
    height: 90px;
  }
  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 100%;

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #E6E9F3;
    }

    td {
      border: none;
      border-bottom: 1px solid #E6E9F3;
      position: relative;
      padding-left: 50%;

      &:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-column);

        color: #000;
        font-weight: bold;
      }
    }
  }
}
.selected {
  background-color: #F5F7FF;
  color: #7671DD;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;