.ShortSchedule {

  .chooseField {
    width: 100%;
    height: 100%;
  }

  .dateWrap {
    width: 37%;
    position: relative;
    margin-left: 10px;
    cursor: pointer;

    input {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #7f8191;
      cursor: pointer;
    }

    svg {
      position: absolute;
      bottom: 20px;
      right: 15px;
      cursor: pointer;
    }
  }

  .react-calendar {
    bottom: -310px;
    left: 0;
  }

  .fieldSubtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #7f8191;
    cursor: pointer;
  }

  .chooseField {
    padding: 12px 30px 12px 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 15px;
    min-height: 64px;
    align-items: center;
    position: relative;
    cursor: pointer;
    background: white;
    margin: 0;
    border: 1px solid #CBCBCB;

    /*  &:hover {
      outline: 2px solid #c5c5c5;
    } */
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #7f8191;
      margin: 0;
      cursor: pointer;
    }

    .fieldValue {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #2e2e3d;
      cursor: pointer;
    }
  }

  .inp {
    input {
      border: none;
      outline: none;
      padding: 0;
      background-color: inherit;
      height: fit-content;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #2e2e3d;
      cursor: pointer;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #2e2e3d;
      }
    }
  }

  .mainCalendarWrap {
    display: flex;
    justify-content: space-between;
  }
}

.ShortSchedule select {
  margin-left: 30px;
  width: 362px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 18px;

  background: #ffffff;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 15px;
}

.ShortSchedule .Select {
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    padding: 0;
  }

  &__controls-wrapper {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1860px) {
      width: 100%;
    }
  }
}

.Date {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.ShortSchedule .Table {
  width: 100%;
  height: 400px;

  background: #fffdfd;

  overflow-y: hidden;

  box-shadow: 0px 6px 18px rgba(54, 55, 64, 0.1);
  border-radius: 15px;
}

.ShortSchedule .Table table {
  width: 100%;
  height: 20px;
}

.ShortSchedule .Table table tr {
  width: 100%;
  height: 78px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border: 1px solid #f3f4f6;
}

.ShortSchedule .Table table tr td {
  align-items: flex-start;
  text-align: left;
  transition: 0.3s all ease;
}

.ShortSchedule .Table .info {
  background: #f5f7ff;

  border-bottom: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: 70px;
  overflow-x: hidden;
  // width: 100%;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .date {
  width: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .time {
  width: 101px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .lesson {
  width: 330px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .coach {
  width: 233px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2E2E3D;
  text-align: left;
}

.ShortSchedule .Table .type {
  width: 120px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .audince {
  width: 68px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  align-items: center;
  overflow-x: scroll;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.ShortSchedule .Table .info .audince {
  width: 80px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  /* or 162% */


  /* black */

  color: #2E2E3D;
}

.Bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2E2E3D;
}

.ShortSchedule .Table .frame {
  width: 75px;
}

.None {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

tr.None {
  border-bottom: none !important;
}

.None h2 {
  transition: 0.3s all ease;
}

.None h2:hover {
  transform: scale(1.00);
}

.ShortSchedule .Table table tr td:hover {
  transform: scale(1.00);
}

.ShortSchedule {
  td {
    cursor: default;
  }

  .chooseFieldY {
    margin-left: 40px;
    margin-right: 0;
    border: 1px solid #CBCBCB;
  }
}

.scheduleNextPrevios {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.4s;

  &:hover {
    transform: scale(1.00);
    transition: transform 0.4s;
  }
}

.react-calendar .off {
  width: 0;
  height: 0;
}

.ScheduleCalendar .react-calendar__tile {
  border: 1px solid #f5f6fa;
}

.ScheduleCalendar .react-calendar__navigation__arrow {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: 600;
  font-size: 22px;

  background: #7671dd;
  border-radius: 10px;
}

.ScheduleCalendar .react-calendar__tile--active {
  background: #7671dd;
  color: white;
}

.ScheduleCalendar .react-calendar__tile--active {
  background-color: #7671dd;
}

.ScheduleCalendar .react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.ScheduleCalendar .react-calendar__navigation__next2-button {
  display: none;
}

.ScheduleCalendar .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.ScheduleCalendar .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.ScheduleCalendar .react-calendar__navigation__label {
  color: #7671dd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.react-calendar {
  position: absolute;
  bottom: -305px;

  width: 306px;
  min-height: 300px;

  background: #ffffff;
  /* line-grey */

  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  /* card-shadow */

  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  padding: 10px;

  transition: 0.3s all ease;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  // border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  align-items: center;
}
.react-calendar__navigation button {
  // min-width: 44px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  // background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  // text-transform: uppercase;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: grey;
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-bottom: 0;
  cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-weight: 700;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  // color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  width: 40px;
  height: 36px;
  text-align: center;
  margin: 1px;
  border: 1px solid #f5f6fa;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.ScheduleCalendar .react-calendar__tile:enabled:hover,
.ScheduleCalendar .react-calendar__tile:enabled:focus {
  background-color: #7671dd;
  color: white;
}
.react-calendar__tile--now {
  background: none;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #a5a1f0;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #7671dd;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #8882fd;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__label {
  background: none;
}

.react-calendar__navigation__label:hover {
  transform: scale(1.00);
}

.react-calendar__navigation__arrow {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: 600;
  font-size: 22px;

  background: #7671dd;
  border-radius: 10px;
}

.react-calendar__navigation__label {
  color: #7671dd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;