.quillContainer {
  min-width: 635px;
  min-height: 300px;
  border: 2px solid #f5f6fa;
  border-radius: 1rem;

  :global {

    .ql-toolbar,
    .ql-container {
      border: none;
    }

    .ql-toolbar {
      border-bottom: 2px solid #f5f6fa;
    }
  }
}

.InputFile {
  margin-bottom: 20px;
  margin-top: 20px;
  min-width: 635px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;