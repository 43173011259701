.LoginLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50%;
  background: #f5f6f9;
  z-index: 1000;
}



@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;