.wrapper {
  display: flex;
  justify-content: space-between;
}

.column {
  flex-basis: 50%;
}


.text {
  color: #7671DD;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  margin-right: 20px;
  font-weight: bold;
  margin-left: -15px;
}

.button {
  margin-top: 20px;
  color: #7671DD;
}
