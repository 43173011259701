.Schedule {
  max-width: 1440px;
  width: 92%;
  margin: auto;
  padding: 0 4%;
  padding-top: 40px;
  box-sizing: content-box;

  td {
    cursor: default;
  }

  &__filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  &__controls-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .Row {
    .text-style {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.5rem;
      color: #7f8191;
    }

    .text-style-choise {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }

  .ShortSchedule {

    .Table {
      .heading-text-style {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        height: 70px;
        font-size: 1rem;
        line-height: 1.625rem;
        color: #2e2e3d;
      }

      .table-text-style {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.625rem;
        color: #2e2e3d;
      }

      .date,
      .time,
      .lesson,
      .coach,
      .type,
      .audince {
        font-size: 16px;
        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
      }
    }
  }
}

.Schedule .Choosed {
  margin: 10px;
}

.Schedule .ActiveC {
  color: #7671dd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Schedule .ActiveC::after {
  content: "";
  width: 40px;
  height: 2px;

  position: relative;
  background-color: #7671dd;
}

.Schedule .Row {
  align-items: center;
}

.ShortSchedule .Select {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ShortSchedule .Table {
  width: 100%;
  height: 440px;

  background: #fffdfd;

  overflow-y: scroll;

  box-shadow: 0px 6px 18px rgba(54, 55, 64, 0.1);
  border-radius: 15px;
}

.ShortSchedule .Table table {
  width: 100%;
  height: 20px;
}

.ShortSchedule .Table table tr {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: fit-content;

  border: 1px solid #f3f4f6;
}

.chooseFieldYX {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.Bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2E2E3D;
}

.chooseFieldY {
  width: 362px;
  padding: 18px;
  border: none;
  display: flex;
  justify-content: space-between;
  // width: 30%;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  min-height: 64px;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  padding-left: 10px;

  display: flex;
  align-items: center;
  // justify-content: center;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
}

.ShortSchedule .Table table tr td {
  align-items: flex-start;
  text-align: left;
}

.ShortSchedule .Table .info {
  background: #f5f7ff;

  border-bottom: none;
}

.ShortSchedule .Table .date {
  width: 30px;
}

.DateS {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #2e2e3d;
}

.ShortSchedule .Table .time {
  width: 40px;
}

.ShortSchedule .Table .lesson {
  width: 330px;
  padding-left: 1rem;

  display: flex;
  align-items: center;
  overflow-y: scroll;
  cursor: default;
}

.ScheChoose {
  width: 100%;

  position: absolute;

  top: 68px;
  left: 0px;

  padding-left: 10px;

  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 20px 0;

  background: white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.OptionChoose {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  padding: 10px;
  width: 100%;
  // white-space: nowrap;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #f5f6fa;
  }
}

.zachem {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #24252e;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.ScheChoose::-webkit-scrollbar {
  width: 0;
}

.ShortSchedule .Table .lesson::-webkit-scrollbar {
  width: 0;
}

.ShortSchedule .Table .coach {
  width: 200px;
}

.ShortSchedule .Table .type {
  width: 120px;
}

.ShortSchedule .Table .audince {
  align-items: left;
  width: 200px;
}

.ShortSchedule .Table .info .audince {
  width: 200px;
  align-items: left;
  font-size: 14px;
}


.ShortSchedule .Table .frame {
  width: 75px;
}

.Schedule .Table table .None {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ShortSchedule .Table {
  margin-bottom: 20px;
}

.Schedule .Cal {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
}

.Schedule .Cal .Click {
  width: 244px;
  height: 60px;
  background: #ffffff;
  border-radius: 15px;
  padding: 6px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

select {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select option {
  padding: 5px;
  margin: 5px;
}

select::-ms-expand {
  display: none;
}

@media screen and (max-width: 1440px) {
  .ShortSchedule .Table table {
    font-size: 12px;
  }

  .ShortSchedule .Table .date,
  .frame {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;