.payment {
  .K-ChooseSpec {
    min-width: fit-content;
    margin: 20px 0;
    position: relative;
  }
  .scolTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    color: #2e2e3d;
  }
}

.scol {
  .debt {
    background: #ff5e5e;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .acur {
    background: #00BA95;;
    border-radius: 30px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}

.refPopup {
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  box-shadow: 0px 12.5216px 6.63642px rgba(0, 0, 0, 0.015);
  border-radius: 20px;
  width: max-content; // Ensures the popup width fits its content
  position: absolute;
  left: 50%; // Center aligned horizontally
  transform: translateX(-50%); // Shift left by half of the popup's width to center it
  top: 100%; // Place it directly below the button
  padding: 10px 0 20px 0;
  z-index: 5;
}

.hidden {
  display: none;
}

.vstack__container {
  margin: 20px 10px;
}

.inp {
  margin-top: 10px;
  min-width: 600px;
  max-width: 600px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;