.primary {
    color: var(--text-redesigned);

    &:hover {
        color: var(--accent-redesigned);
    }
}

.red {
    color: var(--cancel-redesigned);

    &:hover {
        opacity: 0.8;
    }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;