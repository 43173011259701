
.ArrowLeftN {
  width: 32px;
  height: 32px;

  background-color: white;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  left: 5%;

  z-index: 20;
}

.ArrowLeftNimg {
  width: 20px;
}

.ArrowLeftN img {
  height: 20px;
  width: 20px;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;