.title {
    // color: black;
    margin: 0;
}

.text {
    // color: black;
    margin: 0;
}

.error {
    .title {
        color: red;
    }

    .text {
        color: red;
    }
}

.accent {
    .title {
        color: white;
    }

    .text {
        color: white;
    }
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.size_s {
    .title {
        font-size: 18px;
    }

    .text {
        font-size: 16px;
    }
}

.size_m {
    .title {
        font-size: 24px;
    }

    .text {
        font-size: 20px;
    }
}

.size_l {
    .title {
        font-size: 32px;
    }

    .text {
        font-size: 24px;
    }
}

.bold {
    .title, .text {
        font-weight: bold;
    }
}

.pointer {
    .title, .text {
        cursor: pointer;
    }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;