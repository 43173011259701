.filterDropDown {
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding-right: 1.2rem;
  position: relative;
  min-width: fit-content;
}

.filterDropDownWithBorder {
  border: 2px solid #f5f6fa;
}

.filterDropDownValue {
  font-size: 0.92rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  overflow: hidden;
  padding: 1.2rem;
  white-space: nowrap;
  width: 100%;
}

.filterDropDownOpenedList {
  background: #fff;
  border-radius: 0.6rem;
  box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 1.25rem 0;
  position: absolute;
  top: 4.25rem;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  z-index: 2;
}

.filterDropDownOpenedList::-webkit-scrollbar {
  display: none;
}

.filterDropDownOptionChoose {
  font-size: 0.94rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding: 0.6rem 1.25rem;
  color: #000000; 
}

.filterDropDownOptionChooseWithWrap {
  white-space: unset;
}

.filterDropDownOptionChoose:hover {
  background: #f5f6fa;
}

.filterDropDownTitle {
  padding: 1.2rem 0 1.2rem 1.2rem;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;