.Button {
    cursor: pointer;
    color: white;
    padding: 18px 24px;
    display: flex;
    align-items: center;
}

.withAddon {
    padding: 0 16px;
}

.addonLeft, .addonRight {
    display: flex;
}

.addonRight svg, .addonLeft svg {
    color: #999; /* Assumed color for --hint-redesigned */
}

.clear {
    padding: 0;
    border: none;
    background: none;
    outline: none;
}

.filled {
    border: none;
    outline: none;
    border-radius: 15px;
}

.outline {
    background: none;
    border-radius: 34px;

    &:hover {
        border: 2px solid #007BFF; /* Assumed color for --accent-redesigned */
    }

    &.normal {
        border: 2px solid #6c757d; /* Assumed color for --icon-redesigned */
    }

    &.error {
        border: 2px solid #dc3545; /* Assumed color for --cancel-redesigned */
    }

    &.success {
        border: 2px solid #28a745; /* Assumed color for --save-redesigned */
    }
}

.square {
    padding: 0;
}

.square.m {
    width: 16px; /* Assumed value for --font-line-m */
    height: 16px; /* Assumed value for --font-line-m */
}

.square.l {
    width: 18px; /* Assumed value for --font-line-l */
    height: 18px; /* Assumed value for --font-line-l */
}

.square.xl {
    width: 24px; /* Assumed value for --font-line-xl */
    height: 24px; /* Assumed value for --font-line-xl */
}

.m {
    font-size: 16px;
}

.l {
    font-size: 18px;
}

.xl {
    font-size: 24px;
}

.disabled {
    opacity: 0.5;
}

.fullWidth {
    width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;