.historyWrap {
  display: flex;
  white-space: nowrap;

  .historyLink {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }

  .location {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none;
    color: #7671DD;
    margin: 0;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;