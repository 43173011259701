.button {
  background-color: #F5F6FA;
  color: #000;
  justify-content:'space-between';
  margin-bottom: 10px;
}

.icon {
  background-color: white;
  margin-right: 15px;
  border-radius: 15px;
  padding: 1px;
}

.spoilerItems{
  min-width: 100%;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;