.Icon {
    color: var(--icon-redesigned);
}

.button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.button:hover .Icon {
    color: var(--accent-redesigned);
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;