/* General Styles */
.Schedule {
  max-width: 1440px;
  width: 92%;
  margin: auto;
  padding: 0 4%;
  padding-top: 40px;
  box-sizing: content-box;
}

.Schedule td {
  cursor: default;
}

.Schedule__filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.Schedule__controls-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.Schedule .Row {
  align-items: center;
}

.Schedule .Choosed {
  margin: 10px;
}

.Schedule .ActiveC {
  color: #7671dd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Schedule .ActiveC::after {
  content: "";
  width: 40px;
  height: 2px;
  position: relative;
  background-color: #7671dd;
}

/* Text Styles */
.Schedule .Row .text-style {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #7f8191;
}

.Schedule .Row .text-style-choise {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
}

/* Short Schedule Styles */
.ShortSchedule .Table {
  width: 100%;
  height: fit-content;
  background: #fffdfd;
  overflow-y: scroll;
}

.ShortSchedule .Table table {
  width: 100%;
  height: 30px;
}

.ShortSchedule .Table table tr {
  width: 1438px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
  border: 1px solid #F5F6F9;
}


.ShortSchedule .Table .info {
  background: #f5f7ff;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: hidden;
}

.ShortSchedule .Table .info .audince {
  font-size: 16px;
  width: 130px;
}

.ShortSchedule .Table .info .lesson {
  display: 'block';
  background: #f5f7ff;
  width: 330px;
  border-bottom: none;
}

.ShortSchedule .Table .date {
  display: 'block';
  width: 120px;
  padding: 1.4rem;
  align-items: center;
}

.ShortSchedule .Table .time {
  display: 'block';
  text-align: left;
  align-items: center;
  width: 140px;
  padding: 1.4rem;
}

.ShortSchedule .Table .lesson {
  display: 'block';
  width: 330px;
  padding: 1.4rem;
  align-items: center;
  text-align: left;
  overflow-y: visible;
  overflow-x: hidden;
}

.ShortSchedule .Table .direction {
  display: 'block';
  width: 250px;
  padding-right: 1rem;
  align-items: center;
  align-items: start;
}

.ShortSchedule .Table .prof {
  display: 'block';
  width: 220px;
  align-items: center;
  padding-right: 1rem;
  align-items: start;
  overflow-x: hidden;
  cursor: default;
}

.ShortSchedule .Table .course {
  display: 'block';
  align-items: center;
  align-items: start;
  width: 100px;
  cursor: default;
}

.ShortSchedule .Table .group {
  display: 'block';
  align-items: center;
  align-items: start;
  width: 130px;
  overflow-x: hidden;
  cursor: default;
}

.ShortSchedule .Table .eform {
  display: 'block';
  text-align: left;
  padding-right: 1rem;
  align-items: start;
  width: 180px;
  overflow-x: hidden;
  cursor: default;
}

.ShortSchedule .Table .audince {
  display: 'block';
  align-items: start;
  text-align: left;
  width: 130px;
  font-size: 12px;
  cursor: default;
}


.ShortSchedule .Table .frame {
  width: 75px;
}

.ShortSchedule .Table .heading-text-style {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  height: 70px;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #2e2e3d;
}

.ShortSchedule .Table .table-text-style {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #2e2e3d;
}

/* Responsive Styles */
@media screen and (max-width: 1440px) {
  .ShortSchedule .Table table {
    font-size: 12px;
  }

  .ShortSchedule .Table .date,
  .ShortSchedule .Table .frame {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Additional Styles */
.Schedule .Cal {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Schedule .Cal .Click {
  width: 244px;
  height: 60px;
  background: #ffffff;
  border-radius: 15px;
  padding: 6px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Schedule .None {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chooseFieldYX, .chooseFieldY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.chooseFieldY {
  width: 362px;
  padding: 18px;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  border-radius: 15px;
  min-height: 64px;
  background: #ffffff;
}

.ScheChoose {
  width: 100%;
  position: absolute;
  top: 68px;
  left: 0px;
  padding: 20px 0 20px 10px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ScheChoose::-webkit-scrollbar {
  width: 0;
}

.ShortSchedule .Table .lesson::-webkit-scrollbar {
  width: 0;
}

.OptionChoose {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.OptionChoose:hover {
  background: #f5f6fa;
}

.zachem {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #24252e;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.Bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2E2E3D;
}

.DateS {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #2e2e3d;
}

select {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select option {
  padding: 5px;
  margin: 5px;
}

select::-ms-expand {
  display: none;
}

tr.row:nth-child(even) {
  background-color: #ffffff;
  height: fit-content;
}

tr.row:nth-child(odd) {
  background-color: #F5F6FA;
  height: fit-content;
}

.info {
  height: fit-content;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;