.workspaceIconsList {
  .cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 30px;
  }
  .cards__item {
    display: flex;
  }
  .card {
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 30px;
    width: 337.5px;
  }
  .btn {
    cursor: pointer;
  }
  .card__image {
    max-width: 48px;
  }
  .btn {
    color: #7F8190;
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    font-family: Manrope;
    max-width: 80px;
    position: relative;
    top: 5px;
    left: -5px;
    height: 25px;
  }
  .card__title {
    margin-bottom: 0;
  }
  .btn:after {
    content: "";
    width: 40px;
    height: 40px;
    background-image: url(../../shared/assets/workspaceIcons/arrowBtn.svg);
    position: relative;
    display: inline-block;
    left: 215px;
    top: -30px;
  }
  .btn:hover {
    &:after {
      background-image: url(../../shared/assets/workspaceIcons/arrowBtnHover.svg);
    }
  }
}

.buttonDownload{
  background-color: #7771dd;
  width: 380px;
  height: 60px;
  margin-bottom: 30px;
}
.iconDownload {
  margin-left: 10px;
  color:white;
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;